<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col space-y-8">
        <div>
          <h2 class="font-bold text-2xl mb-4 text-promy-blue-300 sm:text-base">
            Apprenons à nous connaître. <br />
            Qui êtes vous ?
          </h2>
          <div class="flex items-baseline">
            <div class="w-full">
              <div>
                <validation-provider
                  class="relative flex flex-col space-y-2"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="grid gap-2 grid-template-step4">
                    <selectable-button
                      v-for="(type, index) in userTypes"
                      :key="type.name + index"
                      v-model="form.informations.label_profile"
                      :option-name="type.name"
                      :classes="'px-0 text-sm'"
                      @onUpdateOption="change"
                    >
                      <template #icon>
                        <ProIcon
                          class="!h-12 !w-12 mb-1"
                          :name="type.icon"
                          :color="'promy-blue-200'"
                        />
                      </template>
                    </selectable-button>
                  </div>
                  <span v-show="errors[0]" class="text-xs text-promy-red-400">{{
                    errors[0]
                  }}</span>
                  <pro-input
                    v-if="form.informations.label_profile === 'autre'"
                    :placeholder="'Autre'"
                    :rules="
                      form.informations.label_profile === 'autre'
                        ? 'required'
                        : ''
                    "
                    v-model="form.informations.other_label_profile"
                  />
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 class="font-bold text-2xl mb-4 text-promy-blue-300 sm:text-base">
            Quel est le type de votre bien ?
          </h2>
          <div class="flex space-x-4 items-baseline">
            <div>
              <validation-provider
                class="relative flex flex-col space-y-2"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="flex flex-wrap gap-4">
                  <selectable-button
                    v-for="(solde, index) in typeBiens"
                    :key="solde + index"
                    v-model="form.informations.type_bien"
                    :option-name="solde"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
                <pro-input
                  v-if="form.informations.type_bien === 'Autre'"
                  :placeholder="'Autre'"
                  :rules="
                    form.informations.type_bien === 'Autre' ? 'required' : ''
                  "
                  v-model="form.informations.other_type_bien"
                />
              </validation-provider>
            </div>
          </div>
        </div>
        <div>
          <h2 class="font-bold text-2xl mb-4 text-promy-blue-300 sm:text-base">
            Souhaitez-vous vendre votre bien ?
          </h2>
          <div class="flex space-x-4 items-baseline">
            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="flex flex-wrap gap-4">
                  <selectable-button
                    v-for="(solde, index) in soldeList"
                    :key="solde + index"
                    v-model="form.informations.on_sale"
                    :option-name="solde"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
        </div>
        <div>
          <div class="font-bold text-2xl mb-4 text-promy-blue-300 sm:text-base">
            Êtes-vous déjà en contact avec un promoteur/constructeur ?
          </div>
          <validation-provider
            class="flex flex-col mb-4"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex sm:flex-wrap gap-4">
              <pro-radio
                label="Oui"
                :value="form.informations.is_contact_with_promoteur"
                @change="setContactPromoteur($event)"
              >
                Oui
              </pro-radio>
              <pro-radio
                label="Non"
                :value="form.informations.is_contact_with_promoteur"
                @change="setContactPromoteur($event)"
              >
                Non
              </pro-radio>
              <pro-radio
                label="Non, j'aimerais bien"
                :value="form.informations.is_contact_with_promoteur"
                @change="setContactPromoteur($event)"
              >
                Non, j'aimerais bien
              </pro-radio>
            </div>
            <div class="w-full">
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </div>
          </validation-provider>
        </div>
      </div>
    </template>
    <template #buttons>
      <Buttons :form="form" :goNext="invalid" />
    </template>
    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      userTypes: [
        {
          name: 'propriétaire',
          icon: 'owner',
        },
        {
          name: 'co-propriétaire',
          icon: 'co-owner',
        },
        {
          name: 'locataire',
          icon: 'tenant',
        },
        {
          name: 'autre',
          icon: 'other-type',
        },
      ],
      soldeList: ['Oui', 'Non', 'Peut-être'],
      typeBiens: [
        'Maison',
        'Terrain',
        'Immeuble',
        'Appartement',
        'Local',
        'Autre',
      ],
    }
  },

  methods: {
    change(value) {
      this.form.informations.label_profile = value
    },
    setContactPromoteur(value) {
      this.form.informations.is_contact_with_promoteur = value
    },
  },
}
</script>
<style lang="scss" scoped>
.grid-template-step4 {
  grid-template-columns: repeat(auto-fill, 120px);
  @screen sm {
    grid-template-columns: repeat(auto-fill, 133px);
  }
}
</style>
